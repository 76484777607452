import * as React from "react"
import "./scss/navbar.scss"
import { Link } from "gatsby"

export default function Navbar(props) {
	return (
		<main className="navbar">
		<input type="checkbox" className="navbar__toggle" />
			<Link to="/"><img src={'/images/long_logo.png'} alt="logo" className="navbar__logo" /></Link>
			<ul className="navbar__links list-unstyled">
				<Link to="/" className="nostyle"><li className="navbar__links__item">Home</li></Link>
				{/*<Link to="/about" className="nostyle"><li className="navbar__links__item">About</li></Link>*/}
				{/*<Link to="/partners" className="nostyle"><li className="navbar__links__item">Partners</li></Link>*/}
				<Link to="/contact" className="nostyle"><li className="navbar__links__item">Contact</li></Link>
				<a className="nostyle" href="https://vwestjet.org/phpvms/index.php/registration"><li className="navbar__links__item">Apply</li></a>
				<a className="nostyle" href="https://vwestjet.org/phpvms/index.php"><li className="navbar__links__item">Login</li></a>
			</ul>
		</main>
	)
}